export function bytes_to_float(bytes: Array<number> | Uint8Array): number {
  const uint8bytes = Uint8Array.from(bytes);
  const dataview = new DataView(uint8bytes.buffer);
  return Math.fround(dataview.getFloat32(0, false));
}

export function bytes_to_uint32(bytes: Array<number> | Uint8Array): number {
  const uint8bytes = Uint8Array.from(bytes);
  const dataview = new DataView(uint8bytes.buffer);
  return dataview.getUint32(0, false);
}
export function bytes_to_int32(bytes: Array<number> | Uint8Array): number {
  const uint8bytes = Uint8Array.from(bytes);
  const dataview = new DataView(uint8bytes.buffer);
  return dataview.getInt32(0, false);
}

export function bytes_to_uint16(bytes: Array<number> | Uint8Array): number {
  const uint8bytes = Uint8Array.from(bytes);
  const dataview = new DataView(uint8bytes.buffer);
  return dataview.getUint16(0, false);
}

export function bytes_to_string(bytes: Uint8Array): string {
  return new TextDecoder().decode(bytes).split("\0")[0];
  // const uint8bytes = Uint8Array.from(bytes);

  // return String.fromCharCode(bytes as Array<number>);
  // let out_str: string = ""
  // for(let i=0; i<uint8bytes.length;i++){
  //     if (uint8bytes[i]!=0){
  //         out_str += String.fromCharCode(uint8bytes[i]);
  //     }
  // }
  // return out_str
}

//6 bytes to date_time from compressed
export function bytes_to_date_time(bytes: Array<number> | Uint8Array): Date {
  const compressed_date_time = bytes_to_uint32(bytes.slice(0, 4));
  const year = ((compressed_date_time >> 26) & 0b111111) + 2020;
  const month = (compressed_date_time >> 22) & 0b1111;
  const day = (compressed_date_time >> 17) & 0b11111;
  const hour = (compressed_date_time >> 12) & 0b11111;
  const minute = (compressed_date_time >> 6) & 0b111111;
  const second = compressed_date_time & 0b111111;
  const ms = bytes_to_uint16(bytes.slice(4, 6));
  const js_date_time = new Date(year, month - 1, day, hour, minute, second, ms);
  return js_date_time;
}

export function uint32_to_bytes(uint32: number): Uint8Array {
  const buffer = new ArrayBuffer(4);
  new DataView(buffer).setUint32(0, uint32);
  return new Uint8Array(buffer);
}

export function uint16_to_bytes(uint16: number): Uint8Array {
  const buffer = new ArrayBuffer(2);
  new DataView(buffer).setUint16(0, uint16);
  return new Uint8Array(buffer);
}

export function float_to_bytes(float: number): Uint8Array {
  const buffer = new ArrayBuffer(4);
  new DataView(buffer).setFloat32(0, Math.fround(float));
  return new Uint8Array(buffer);
}

/**
 * Converts a string to a byte array.
 * @param str The string to encode
 * @param buffer_size The desired size of the buffer. If null the buffer is the length of string + 0-byte
 * @returns A UInt8Array with the string encoded as ASCII.
 */
export function string_to_bytes(
  str: string,
  buffer_size: number | null = null
): Uint8Array {
  const string_buffer = new Uint8Array(buffer_size ?? str.length + 1);
  new TextEncoder().encodeInto(str, string_buffer);
  return string_buffer;
}

export function date_time_to_bytes(dt: Date): {
  dt_bytes: Uint8Array;
  ms_bytes: Uint8Array;
} {
  const uint32_dt =
    ((dt.getFullYear() - 2020) << 26) +
    ((dt.getMonth() + 1) << 22) +
    (dt.getDate() << 17) +
    (dt.getHours() << 12) +
    (dt.getMinutes() << 6) +
    dt.getSeconds();
  const ms = dt.getMilliseconds();
  return {
    dt_bytes: uint32_to_bytes(uint32_dt),
    ms_bytes: uint16_to_bytes(ms)
  };
}

export function int32_to_bytes(value: number): Uint8Array {
  const buffer = new ArrayBuffer(4);
  new DataView(buffer).setInt32(0, value);
  return new Uint8Array(buffer);
}
