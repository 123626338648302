import { defineStore } from "pinia";
import { PortState, ModbusStore, WriteAccessLevel } from "./interfaces";
import {
  get_params_csv,
  get_device_units_csv,
  get_units_gui_csv,
  DataType,
} from "@/lib/gen_interfaces";
import modbusWorker from "@/lib/modbusworker";
import {
  bytes_to_date_time,
  bytes_to_float,
  bytes_to_int32,
  bytes_to_uint16,
  bytes_to_uint32,
  uint16_to_bytes,
} from "@/lib/byte_utils";

export const useModbusStore = defineStore("modbus", {
  state: (): ModbusStore => ({
    storeInit: false,
    portIndex: -1,
    portState: PortState.disconnected,
    communicationScore: 0,
    deviceAddress: 1,
    params: [],
    writeableParams: [],
    quickParams: [],
    selectedQuickParams: {},
    deviceUnits: [],
    guiUnits: [],
    maxReloadCount: 5,
    modbusValues: {},
    modbusIsChanged: false,
    modbusAddrParams: {},
    modbusAddrValue: {},
    modbusAddrDefaultValue: {},
    modbusAddrLength: {},
    modbusAddrReadBusy: {},
    modbusAddrWriteBusy: {},
    modbusAddrErrMsg: {},
    modbusRefUnit: {},
    wirteAccessLevel: WriteAccessLevel.normal,
    groups: [],
    subgroups: {},
    lastGroup: "0",
    lastSubgroup: "0",
    maxTime: 240, //500 seconds
    isPollingMode: false,
    pollingFlag: false,
    pollingTimer: 0,
    pollingDataX: [],
    pollingDataY: {},
    isDemoMode: false,
    demoFlag: false,
    demoTimer: 0,
    demoDataX: [],
    demoDataY: {},
    log_toggle_zoom: null,
    logDateState: 0,
    log7dMinTime: 0,
    log7dMaxTime: 0,
    slider_7d: [0, 100],
    slider_1m: [0, 100],
    slider_1s: [0, 100],
    slider_100ms: [0, 100],
    log1mDataIsOk: false,
    log1mDataTime: [],
    log1mDataRP: [],
    log1mDataS1: [],
    log1mDataS2: [],
    log1mDataM12: [],
    log1mDataSetpoint: [],
    log1mDataV1: [],
    log1mDataV2: [],
    log1mDataTemp: [],
    log1mDataBusy: false,
    log1sDataIsOk: false,
    log1sDataTime: [],
    log1sDataRP: [],
    log1sDataS1: [],
    log1sDataS2: [],
    log1sDataM12: [],
    log1sDataSetpoint: [],
    log1sDataV1: [],
    log1sDataV2: [],
    log1sDataTemp: [],
    log1sDataBusy: false,
    log100msDataIsOk: false,
    log100msDataTime: [],
    log100msDataRP: [],
    log100msDataS1: [],
    log100msDataS2: [],
    log100msDataM12: [],
    log100msDataSetpoint: [],
    log100msDataV1: [],
    log100msDataV2: [],
    log100msDataTemp: [],
    log100msDataBusy: false,
  }),
  actions: {
    async initLoad() {
      const paramsfile = await get_params_csv();
      if (paramsfile == null) {
        return null;
      }
      this.params = paramsfile.filter(
        (item) => item.name != "0" && item.type != "0"
      );

      const device_units = await get_device_units_csv();
      if (device_units == null) {
        return null;
      }
      this.deviceUnits = device_units;

      const gui_units = await get_units_gui_csv();
      if (gui_units == null) {
        return null;
      }
      this.guiUnits = gui_units;

      //------ Get Group & SubGroup------------------------------------
      let char2_mbus_addr = "";
      for (let i = 0; i < this.params.length; i++) {
        const param = this.params[i];
        if (!this.groups.find((g) => g == param.group)) {
          if (param.group != "0") {
            this.groups.push(param.group);
          }
        }
        if (param.sub_group != "" && param.sub_group != "0") {
          if (param.group != "" && param.group != "0") {
            if (!this.subgroups[param.group]) {
              this.subgroups[param.group] = [param.sub_group];
            } else {
              if (
                !this.subgroups[param.group].find((g) => g == param.sub_group)
              ) {
                this.subgroups[param.group].push(param.sub_group);
              }
            }
          }
        }
        this.modbusAddrParams[param["mbus address"]] = param;
        this.modbusAddrReadBusy[param["mbus address"]] = false;
        this.modbusAddrWriteBusy[param["mbus address"]] = false;
        this.modbusAddrErrMsg[param["mbus address"]] = "empty data";
        switch (param.type) {
          case "bool":
            this.modbusAddrLength[param["mbus address"]] = 1;
            if (parseInt(param.default) == 1) {
              this.modbusAddrDefaultValue[param["mbus address"]] = true;
            } else {
              this.modbusAddrDefaultValue[param["mbus address"]] = false;
            }
            break;
          case "uint16_t":
            this.modbusAddrLength[param["mbus address"]] = 1;
            this.modbusAddrDefaultValue[param["mbus address"]] = parseInt(
              param.default
            );
            break;
          case "uint32_t":
            this.modbusAddrLength[param["mbus address"]] = 2;
            this.modbusAddrDefaultValue[param["mbus address"]] = parseInt(
              param.default
            );
            break;
          case "float":
            this.modbusAddrLength[param["mbus address"]] = 2;
            this.modbusAddrDefaultValue[param["mbus address"]] = parseFloat(
              param.default
            );
            break;
          case "int":
            this.modbusAddrLength[param["mbus address"]] = 2;
            this.modbusAddrDefaultValue[param["mbus address"]] = parseInt(
              param.default
            );
            break;
          case "char2":
            if (param.name.indexOf("[0:1]") > 0) {
              char2_mbus_addr = param["mbus address"];
              this.modbusAddrLength[char2_mbus_addr] = 1;
              if (param.default.trim() == "") {
                this.modbusAddrDefaultValue[char2_mbus_addr] = "";
              } else {
                this.modbusAddrDefaultValue[char2_mbus_addr] = param.default;
              }
            } else {
              this.modbusAddrLength[char2_mbus_addr]++;
              if (param.default.trim() != "") {
                this.modbusAddrDefaultValue[char2_mbus_addr] += param.default;
              }
            }
            break;
          default:
            this.modbusAddrLength[param["mbus address"]] = 0;
            this.modbusAddrDefaultValue[param["mbus address"]] = 0;
        }

        this.selectedQuickParams[param["mbus address"]] = false;
        const write_access = param["write access"]
          ? param["write access"]
          : "0";
        const write_access_val = write_access.match(/[1-3]/);
        const write_access_level = write_access_val ? write_access_val[0] : "5";
        if (
          param.input_type != "checkbox" &&
          param.type != "char2" &&
          param["read-only"] == "false" &&
          parseInt(param["mbus address"].toString()) > 9 &&
          write_access_level == "1"
        ) {
          this.quickParams.push(param);
        }

        if (param["read-only"] == "false") {
          this.writeableParams.push(param);
        }
      }

      // console.log("defaut: ", this.modbusAddrDefaultValue);

      this.pollingDataY["RS"] = []; //Reference Sensor
      this.pollingDataY["ISA"] = []; //Input Sensor A
      this.pollingDataY["ISB"] = []; //Input Sensor B
      this.pollingDataY["ISC"] = []; //Input Sensor C
      this.pollingDataY["SP"] = []; //Setpoint
      this.pollingDataY["IV"] = []; //Intake valve
      this.pollingDataY["EV"] = []; //Exhaust valve
      this.pollingDataY["IEV"] = []; //Combined Intake and Exhaust valves
      this.pollingDataY["DT"] = []; //Device Temperature

      this.demoDataY["RS"] = []; //Reference Sensor
      this.demoDataY["ISA"] = []; //Input Sensor A
      this.demoDataY["ISB"] = []; //Input Sensor B
      this.demoDataY["ISC"] = []; //Input Sensor C
      this.demoDataY["SP"] = []; //Setpoint
      this.demoDataY["IV"] = []; //Intake valve
      this.demoDataY["EV"] = []; //Exhaust valve
      this.demoDataY["IEV"] = []; //Combined Intake and Exhaust valves
      this.demoDataY["DT"] = []; //Device Temperature

      this.storeInit = true;
    },
    openSerial(portIndex: number, baud: number, deviceAddr: number) {
      this.portState = PortState.connecting;
      modbusWorker.postMsgopenPort(portIndex, baud).then((reply: boolean) => {
        if (reply) {
          this.deviceAddress = deviceAddr;
          this.portIndex = portIndex;
        }
        modbusWorker.postMsgGetPortState().then((reply: boolean) => {
          if (reply) {
            this.portState = PortState.connected;
            this.portIndex = portIndex;
          } else {
            this.portState = PortState.disconnected;
            this.portIndex = -1;
          }
        });
      });
    },
    closeSerial() {
      this.portState = PortState.connecting;
      modbusWorker.postMsgClosePort().then(() => {
        modbusWorker.postMsgGetPortState().then((reply: boolean) => {
          if (reply) {
            this.portState = PortState.connected;
          } else {
            this.portState = PortState.disconnected;
            this.portIndex = -1;
          }
        });
      });
    },
    modbusRead(
      deviceAddr: number,
      modbusAddr: number,
      counts: number /*count of register(16bits)*/
    ) {
      if (this.portState == PortState.connected) {
        for (let i = 0; i < counts; i++) {
          this.modbusAddrReadBusy[parseInt(modbusAddr.toString()) + i] = true;
        }
        this.modbusAddrValue[modbusAddr] = null;
        modbusWorker
          .postMsgModbusRead(deviceAddr, modbusAddr, counts)
          .then((reply: number[]) => {
            if (reply.length > 1) {
              if (reply[0] == 6000) {
                switch (reply[2]) {
                  case 0x00:
                    this.wirteAccessLevel = WriteAccessLevel.normal;
                    break;
                  case 0x71:
                    this.wirteAccessLevel = WriteAccessLevel.medium;
                    break;
                  case 0x81:
                    this.wirteAccessLevel = WriteAccessLevel.full;
                    break;
                  default:
                    this.wirteAccessLevel = WriteAccessLevel.normal;
                }
              } else {
                // let res: number[] = [];
                if (reply[0] in this.modbusAddrParams) {
                  if (
                    //---------- bool -----------------------
                    this.modbusAddrParams[parseInt(reply[0].toString())].type ==
                      "bool" &&
                    reply.length == 3
                  ) {
                    if (reply[2] == 0) {
                      this.modbusAddrValue[reply[0]] = false;
                    } else {
                      this.modbusAddrValue[reply[0]] = true;
                    }
                  } else if (
                    //---------- uint16_t -----------------------
                    this.modbusAddrParams[parseInt(reply[0].toString())].type ==
                      "uint16_t" &&
                    reply.length == 3
                  ) {
                    this.modbusAddrValue[reply[0]] = bytes_to_uint16([
                      reply[1],
                      reply[2],
                    ]);
                  } else if (
                    //---------- uint32_t -----------------------
                    this.modbusAddrParams[parseInt(reply[0].toString())].type ==
                      "uint32_t" &&
                    reply.length == 5
                  ) {
                    this.modbusAddrValue[reply[0]] = bytes_to_uint32([
                      reply[1],
                      reply[2],
                      reply[3],
                      reply[4],
                    ]);
                  } else if (
                    //---------- int -----------------------
                    this.modbusAddrParams[parseInt(reply[0].toString())].type ==
                      "int" &&
                    reply.length == 5
                  ) {
                    this.modbusAddrValue[reply[0]] = bytes_to_int32([
                      reply[1],
                      reply[2],
                      reply[3],
                      reply[4],
                    ]);
                  } else if (
                    //---------- float -----------------------
                    this.modbusAddrParams[parseInt(reply[0].toString())].type ==
                      "float" &&
                    reply.length == 5
                  ) {
                    this.modbusAddrValue[reply[0]] = parseFloat(
                      bytes_to_float([
                        reply[1],
                        reply[2],
                        reply[3],
                        reply[4],
                      ]).toFixed(5)
                    );
                  } else if (
                    //---------- char2 -----------------------
                    this.modbusAddrParams[parseInt(reply[0].toString())].type ==
                    "char2"
                  ) {
                    let str = "";
                    for (let i = 1; i < reply.length; i++) {
                      if (reply[i] != 0) {
                        str = str.concat(String.fromCharCode(reply[i]));
                      }
                    }
                    this.modbusAddrValue[reply[0]] = str;
                  }
                }
                //----------------------------------------------------
                for (let i = 1; i < reply.length; i += 2) {
                  this.modbusValues[
                    parseInt(reply[0].toString()) + (i - 1) / 2
                  ] = bytes_to_uint16([reply[i], reply[i + 1]]);
                }

                if (reply[0] >= 5006 && reply[0] < 5020) {
                  this.logDateState++;
                }
              }
              if (this.communicationScore < 10) {
                this.communicationScore++;
              }
              for (let i = 0; i < counts; i++) {
                this.modbusAddrErrMsg[parseInt(modbusAddr.toString()) + i] = "";
              }
            } else {
              if (this.communicationScore > 0) {
                this.communicationScore--;
              }
              if (this.communicationScore == 0) {
                for (let i = 0; i < counts; i++) {
                  this.modbusValues[parseInt(modbusAddr.toString()) + i] = 0;
                }
              }
              for (let i = 0; i < counts; i++) {
                this.modbusAddrErrMsg[parseInt(modbusAddr.toString()) + i] =
                  "Read Error. Please reload!";
              }
              console.error(
                "Read error\naddr: ",
                modbusAddr,
                " counts: ",
                counts
              );
            }
            for (let i = 0; i < counts; i++) {
              this.modbusAddrReadBusy[parseInt(modbusAddr.toString()) + i] =
                false;
            }
          });
      } else {
        console.error("port disconnected");
      }
    },
    modbusWrite(
      deviceAddr: number,
      modbusAddr: number,
      counts: number,
      values: Uint8Array
    ) {
      if (this.portState == PortState.connected) {
        for (let i = 0; i < counts; i++) {
          this.modbusAddrWriteBusy[parseInt(modbusAddr.toString()) + i] = true;
        }
        modbusWorker
          .postMsgModbusWrite(deviceAddr, modbusAddr, counts, values)
          .then((reply: number[]) => {
            if (reply.length > 1) {
              if (reply[1] == 0x01) {
                for (let i = 0; i < counts; i++) {
                  this.modbusAddrErrMsg[parseInt(modbusAddr.toString()) + i] =
                    "";
                }
                this.modbusIsChanged = true;
                this.modbusRead(deviceAddr, modbusAddr, counts);
              }
            } else {
              for (let i = 0; i < counts; i++) {
                this.modbusAddrErrMsg[parseInt(modbusAddr.toString()) + i] =
                  "Write Error. Please rewrite!";
              }
              console.error(
                "write error\naddr: ",
                modbusAddr,
                " counts: ",
                counts,
                "values:",
                values
              );
            }
            for (let i = 0; i < counts; i++) {
              this.modbusAddrWriteBusy[parseInt(modbusAddr.toString()) + i] =
                false;
            }
          });
      } else {
        console.error("port disconnected");
      }
    },
    getShowValue(modbusAddr: number, _type: DataType | "0" | null) {
      switch (_type) {
        case "float":
          if (
            this.modbusValues[modbusAddr] == undefined ||
            this.modbusValues[modbusAddr + 1] == undefined
          ) {
            return null;
          } else {
            const byte0 = uint16_to_bytes(this.modbusValues[modbusAddr]);
            const byte1 = uint16_to_bytes(this.modbusValues[modbusAddr + 1]);
            return bytes_to_float([byte0[0], byte0[1], byte1[0], byte1[1]]);
          }
        case "int":
          if (
            this.modbusValues[modbusAddr] == undefined ||
            this.modbusValues[modbusAddr + 1] == undefined
          ) {
            return null;
          } else {
            const byte0 = uint16_to_bytes(this.modbusValues[modbusAddr]);
            const byte1 = uint16_to_bytes(this.modbusValues[modbusAddr + 1]);
            return bytes_to_int32([byte0[0], byte0[1], byte1[0], byte1[1]]);
          }
        case "uint32_t":
          if (
            this.modbusValues[modbusAddr] == undefined ||
            this.modbusValues[modbusAddr + 1] == undefined
          ) {
            return null;
          } else {
            const byte0 = uint16_to_bytes(this.modbusValues[modbusAddr]);
            const byte1 = uint16_to_bytes(this.modbusValues[modbusAddr + 1]);
            return bytes_to_uint32([byte0[0], byte0[1], byte1[0], byte1[1]]);
          }
        case "uint16_t":
          if (this.modbusValues[modbusAddr] == undefined) {
            return null;
          } else {
            return this.modbusValues[modbusAddr];
          }
        case "bool":
          if (this.modbusValues[modbusAddr] == undefined) {
            return null;
          } else {
            if (this.modbusValues[modbusAddr] == 0) {
              return false;
            } else {
              return true;
            }
          }
      }
    },
    convert(
      value: number,
      fromUnit: string | undefined,
      toUnit: string | undefined
    ): number {
      if (fromUnit == undefined || toUnit == undefined) {
        return value;
      }
      if (fromUnit == toUnit) {
        return value;
      }
      const from_unit = this.guiUnits.find((item) => item.name == fromUnit);
      const to_unit = this.guiUnits.find((item) => item.name == toUnit);
      const base = from_unit
        ? value * parseFloat(from_unit?.factor) + parseFloat(from_unit?.offset)
        : value;
      const target = to_unit
        ? (base - parseFloat(to_unit.offset)) / parseFloat(to_unit.factor)
        : base;
      return target;
    },
    pollingData(deviceAddr: number) {
      if (this.portState == PortState.connected) {
        modbusWorker
          .postMsgModbusPollingData(deviceAddr)
          .then((reply: number[]) => {
            if (reply.length > 1) {
              // console.log("response: ", reply);
              if (reply[0] == this.deviceAddress && reply[1] == 0x41) {
                this.pollingDataX = this.pollingDataX.concat(
                  bytes_to_date_time(reply.slice(2, 8)).toISOString()
                );
                this.pollingDataY["RS"] = this.pollingDataY["RS"].concat(
                  bytes_to_float(reply.slice(8, 12))
                );
                this.pollingDataY["ISA"] = this.pollingDataY["ISA"].concat(
                  bytes_to_float(reply.slice(12, 16))
                );
                this.pollingDataY["ISB"] = this.pollingDataY["ISB"].concat(
                  bytes_to_float(reply.slice(16, 20))
                );
                this.pollingDataY["ISC"] = this.pollingDataY["ISC"].concat(
                  bytes_to_float(reply.slice(20, 24))
                );
                this.pollingDataY["SP"] = this.pollingDataY["SP"].concat(
                  bytes_to_float(reply.slice(24, 28))
                );
                const iv = bytes_to_float(reply.slice(28, 32));
                const ev = bytes_to_float(reply.slice(32, 36));
                this.pollingDataY["IV"] = this.pollingDataY["IV"].concat(iv);
                this.pollingDataY["EV"] = this.pollingDataY["EV"].concat(ev);
                this.pollingDataY["IEV"] = this.pollingDataY["IEV"].concat(
                  iv - ev
                );
                this.pollingDataY["DT"] = this.pollingDataY["DT"].concat(
                  bytes_to_float(reply.slice(36, 40))
                );

                this.pollingFlag = !this.pollingFlag;
              }
            } else {
              console.error("response error");
            }
          });

        // //-------- for test data -----------------
        // let wr_msg: number[] = [1, 65];
        // const datetime = new Date();
        // const now = Date.now();
        // const time_bytes = date_time_to_bytes(datetime);
        // wr_msg = wr_msg.concat(Array.from(time_bytes.dt_bytes));
        // wr_msg = wr_msg.concat(Array.from(time_bytes.ms_bytes));
        // const rs_bytes = float_to_bytes(Math.sin(now / 500) * 100 + 10);
        // wr_msg = wr_msg.concat(Array.from(rs_bytes));
        // const s1_bytes = float_to_bytes(
        //   Math.sin((now - 10000) / 500) * 70 + 20
        // );
        // wr_msg = wr_msg.concat(Array.from(s1_bytes));
        // const s2_bytes = float_to_bytes(
        //   Math.sin((now - 20000) / 500) * 60 + 30
        // );
        // wr_msg = wr_msg.concat(Array.from(s2_bytes));
        // const s3_bytes = float_to_bytes(
        //   Math.sin((now - 30000) / 500) * 50 + 40
        // );
        // wr_msg = wr_msg.concat(Array.from(s3_bytes));
        // const sp_bytes = float_to_bytes(Math.sin((now - 40000) / 500) * 10);
        // wr_msg = wr_msg.concat(Array.from(sp_bytes));
        // const v1_bytes = float_to_bytes(Math.sin((now - 50000) / 500) * 20);
        // wr_msg = wr_msg.concat(Array.from(v1_bytes));
        // const v2_bytes = float_to_bytes(Math.sin((now - 60000) / 500) * 20);
        // wr_msg = wr_msg.concat(Array.from(v2_bytes));
        // const dt_bytes = float_to_bytes(Math.sin((now - 70000) / 500) * 5);
        // wr_msg = wr_msg.concat(Array.from(dt_bytes));

        // const len = this.pollingDataX.length;
        // if (len >= this.maxTime * 10) {
        //   this.pollingDataX.shift();
        //   this.pollingDataY["RS"].shift();
        //   this.pollingDataY["ISA"].shift();
        //   this.pollingDataY["ISB"].shift();
        //   this.pollingDataY["ISC"].shift();
        //   this.pollingDataY["SP"].shift();
        //   this.pollingDataY["IV"].shift();
        //   this.pollingDataY["EV"].shift();
        //   this.pollingDataY["IEV"].shift();
        //   this.pollingDataY["DT"].shift();
        // }

        // this.pollingDataX = this.pollingDataX.concat(
        //   bytes_to_date_time(wr_msg.slice(2, 8)).toISOString()
        // );
        // this.pollingDataY["RS"] = this.pollingDataY["RS"].concat(
        //   bytes_to_float(wr_msg.slice(8, 12))
        // );
        // this.pollingDataY["ISA"] = this.pollingDataY["ISA"].concat(
        //   bytes_to_float(wr_msg.slice(12, 16))
        // );
        // this.pollingDataY["ISB"] = this.pollingDataY["ISB"].concat(
        //   bytes_to_float(wr_msg.slice(16, 20))
        // );
        // this.pollingDataY["ISC"] = this.pollingDataY["ISC"].concat(
        //   bytes_to_float(wr_msg.slice(20, 24))
        // );
        // this.pollingDataY["SP"] = this.pollingDataY["SP"].concat(
        //   bytes_to_float(wr_msg.slice(24, 28))
        // );
        // const iv = bytes_to_float(wr_msg.slice(28, 32));
        // const ev = bytes_to_float(wr_msg.slice(32, 36));
        // this.pollingDataY["IV"] = this.pollingDataY["IV"].concat(iv);
        // this.pollingDataY["EV"] = this.pollingDataY["EV"].concat(ev);
        // this.pollingDataY["IEV"] = this.pollingDataY["IEV"].concat(iv - ev);
        // this.pollingDataY["DT"] = this.pollingDataY["DT"].concat(
        //   bytes_to_float(wr_msg.slice(36, 40))
        // );
        //----------- end test ------------------------------------
        // this.pollingFlag = !this.pollingFlag;
      } else {
        console.error("port disconnected");
      }
    },
    getLogDate() {
      if (this.portState == PortState.connected) {
        this.logDateState = 0;
        const base_add = 5006;
        for (let i = 0; i < 7; i++) {
          this.modbusRead(this.deviceAddress, base_add + i * 2, 2);
        }
      } else {
        console.error("port disconnected");
      }
    },
    clearLog1mData() {
      this.log1mDataTime = [];
      this.log1mDataRP = [];
      this.log1mDataS1 = [];
      this.log1mDataS2 = [];
      this.log1mDataM12 = [];
      this.log1mDataSetpoint = [];
      this.log1mDataV1 = [];
      this.log1mDataV2 = [];
      this.log1mDataTemp = [];
    },
    clearLog1sData() {
      this.log1sDataTime = [];
      this.log1sDataRP = [];
      this.log1sDataS1 = [];
      this.log1sDataS2 = [];
      this.log1sDataM12 = [];
      this.log1sDataSetpoint = [];
      this.log1sDataV1 = [];
      this.log1sDataV2 = [];
      this.log1sDataTemp = [];
    },
    clearLog100msData() {
      this.log100msDataTime = [];
      this.log100msDataRP = [];
      this.log100msDataS1 = [];
      this.log100msDataS2 = [];
      this.log100msDataM12 = [];
      this.log100msDataSetpoint = [];
      this.log100msDataV1 = [];
      this.log100msDataV2 = [];
      this.log100msDataTemp = [];
    },
    readLog1mData(deviceAddr: number, dt_start: number, dt_end: number) {
      if (this.portState == PortState.connected) {
        this.log1mDataIsOk = false;
        this.log1mDataBusy = true;
        modbusWorker
          .postMsgModbusLogData(deviceAddr, dt_start, dt_end, "minute")
          .then((reply: number[]) => {
            if (reply.length > 1) {
              // console.log("response: ", reply);
              const count = Math.floor((reply.length - 4) / 38);
              if (
                reply[0] == this.deviceAddress &&
                reply[1] == 0x42 &&
                count == Math.floor((dt_end - dt_start) / 60000) + 1
              ) {
                for (let i = 0; i < count; i++) {
                  const pos = i * 38 + 2;
                  if (reply[pos] != 255) {
                    // this.log1mDataTime.push(
                    //   bytes_to_date_time(reply.slice(pos, pos + 6)).getTime()
                    // );
                    this.log1mDataTime.push(dt_start + i * 60 * 1000);
                    this.log1mDataRP.push(
                      bytes_to_float(reply.slice(pos + 6, pos + 10))
                    );
                    this.log1mDataS1.push(
                      bytes_to_float(reply.slice(pos + 10, pos + 14))
                    );
                    this.log1mDataS2.push(
                      bytes_to_float(reply.slice(pos + 14, pos + 18))
                    );
                    this.log1mDataM12.push(
                      bytes_to_float(reply.slice(pos + 18, pos + 22))
                    );
                    this.log1mDataSetpoint.push(
                      bytes_to_float(reply.slice(pos + 22, pos + 26))
                    );
                    this.log1mDataV1.push(
                      bytes_to_float(reply.slice(pos + 26, pos + 30))
                    );
                    this.log1mDataV2.push(
                      bytes_to_float(reply.slice(pos + 30, pos + 34))
                    );
                    this.log1mDataTemp.push(
                      bytes_to_float(reply.slice(pos + 34, pos + 38))
                    );
                  }
                }
                this.log1mDataIsOk = true;
              }
            } else {
              console.error("response error");
              // this.log1mDataTime.push(dt_start);
              // this.log1mDataRP.push(null);
              // this.log1mDataS1.push(null);
              // this.log1mDataS2.push(null);
              // this.log1mDataM12.push(null);
              // this.log1mDataSetpoint.push(null);
              // this.log1mDataV1.push(null);
              // this.log1mDataV2.push(null);
              // this.log1mDataTemp.push(null);
              // for (let i = 0; i < 6; i++) {
              //   const dt_stamp = dt_start + (i * (dt_end - dt_start)) / 5;
              //   this.log1mDataTime.push(dt_stamp);
              //   this.log1mDataRP.push(Math.sin(dt_stamp / 1000000) * 20 + 20);
              //   this.log1mDataS1.push(
              //     Math.sin(dt_stamp / 1000000 - 100000) * 20 + 20
              //   );
              //   this.log1mDataS2.push(
              //     Math.sin(dt_stamp / 1000000 - 200000) * -20 + 20
              //   );
              //   this.log1mDataM12.push(
              //     Math.sin(dt_stamp / 1000000 - 300000) * 20 + 10
              //   );
              //   this.log1mDataSetpoint.push(
              //     Math.sin(dt_stamp / 1000000 - 500000) * 20 + 30
              //   );
              //   this.log1mDataV1.push(1 * Math.sin(dt_stamp / 1000000));
              //   this.log1mDataV2.push(-1 * Math.sin(dt_stamp / 1000000));
              //   this.log1mDataTemp.push(Math.sin(dt_stamp / 1000000) * 10 + 30);
              // }
            }
            this.log1mDataBusy = false;
          });
      } else {
        console.error("port disconnected");
      }
    },
    readLog1sData(deviceAddr: number, dt_start: number, dt_end: number) {
      if (this.portState == PortState.connected) {
        this.log1sDataIsOk = false;
        this.log1sDataBusy = true;
        modbusWorker
          .postMsgModbusLogData(deviceAddr, dt_start, dt_end, "second")
          .then((reply: number[]) => {
            if (reply.length > 1) {
              // console.log("response: ", reply);
              const count = Math.floor((reply.length - 4) / 38);
              if (
                reply[0] == this.deviceAddress &&
                reply[1] == 0x42 &&
                count == Math.floor((dt_end - dt_start) / 1000) + 1
              ) {
                for (let i = 0; i < count; i++) {
                  const pos = i * 38 + 2;
                  if (reply[pos] != 255) {
                    // this.log1sDataTime.push(
                    //   bytes_to_date_time(reply.slice(pos, pos + 6)).getTime()
                    // );
                    this.log1sDataTime.push(dt_start + i * 1000);
                    this.log1sDataRP.push(
                      bytes_to_float(reply.slice(pos + 6, pos + 10))
                    );
                    this.log1sDataS1.push(
                      bytes_to_float(reply.slice(pos + 10, pos + 14))
                    );
                    this.log1sDataS2.push(
                      bytes_to_float(reply.slice(pos + 14, pos + 18))
                    );
                    this.log1sDataM12.push(
                      bytes_to_float(reply.slice(pos + 18, pos + 22))
                    );
                    this.log1sDataSetpoint.push(
                      bytes_to_float(reply.slice(pos + 22, pos + 26))
                    );
                    this.log1sDataV1.push(
                      bytes_to_float(reply.slice(pos + 26, pos + 30))
                    );
                    this.log1sDataV2.push(
                      bytes_to_float(reply.slice(pos + 30, pos + 34))
                    );
                    this.log1sDataTemp.push(
                      bytes_to_float(reply.slice(pos + 34, pos + 38))
                    );
                  }
                }
                this.log1sDataIsOk = true;
              }
            } else {
              console.error("response error");
              // this.log1sDataTime.push(dt_start);
              // this.log1sDataRP.push(null);
              // this.log1sDataS1.push(null);
              // this.log1sDataS2.push(null);
              // this.log1sDataM12.push(null);
              // this.log1sDataSetpoint.push(null);
              // this.log1sDataV1.push(null);
              // this.log1sDataV2.push(null);
              // this.log1sDataTemp.push(null);
              // for (let i = 0; i < 6; i++) {
              //   const dt_stamp = dt_start + (i * (dt_end - dt_start)) / 5;
              //   this.log1sDataTime.push(dt_stamp);
              //   this.log1sDataRP.push(Math.sin(dt_stamp / 1000000) * 20 + 20);
              //   this.log1sDataS1.push(
              //     Math.sin(dt_stamp / 1000000 - 100000) * 20 + 20
              //   );
              //   this.log1sDataS2.push(
              //     Math.sin(dt_stamp / 1000000 - 200000) * -20 + 20
              //   );
              //   this.log1sDataM12.push(
              //     Math.sin(dt_stamp / 1000000 - 300000) * 20 + 10
              //   );
              //   this.log1sDataSetpoint.push(
              //     Math.sin(dt_stamp / 1000000 - 500000) * 20 + 30
              //   );
              //   this.log1sDataV1.push(1 * Math.sin(dt_stamp / 1000000));
              //   this.log1sDataV2.push(-1 * Math.sin(dt_stamp / 1000000));
              //   this.log1sDataTemp.push(Math.sin(dt_stamp / 1000000) * 10 + 30);
              // }
            }
            this.log1sDataBusy = false;
          });
      } else {
        console.error("port disconnected");
      }
    },
    readLog100msData(deviceAddr: number, dt_start: number, dt_end: number) {
      if (this.portState == PortState.connected) {
        this.log100msDataIsOk = false;
        this.log100msDataBusy = true;
        modbusWorker
          .postMsgModbusLogData(deviceAddr, dt_start, dt_end, "ms")
          .then((reply: number[]) => {
            if (reply.length > 1) {
              // console.log("response: ", reply);
              const count = Math.floor((reply.length - 4) / 38);
              if (
                reply[0] == this.deviceAddress &&
                reply[1] == 0x42 &&
                count == Math.floor((dt_end - dt_start) / 100) + 1
              ) {
                for (let i = 0; i < count; i++) {
                  const pos = i * 38 + 2;
                  if (reply[pos] != 255) {
                    // this.log100msDataTime.push(
                    //   bytes_to_date_time(reply.slice(pos, pos + 6)).getTime()
                    // );
                    this.log100msDataTime.push(dt_start + i * 100);
                    this.log100msDataRP.push(
                      bytes_to_float(reply.slice(pos + 6, pos + 10))
                    );
                    this.log100msDataS1.push(
                      bytes_to_float(reply.slice(pos + 10, pos + 14))
                    );
                    this.log100msDataS2.push(
                      bytes_to_float(reply.slice(pos + 14, pos + 18))
                    );
                    this.log100msDataM12.push(
                      bytes_to_float(reply.slice(pos + 18, pos + 22))
                    );
                    this.log100msDataSetpoint.push(
                      bytes_to_float(reply.slice(pos + 22, pos + 26))
                    );
                    this.log100msDataV1.push(
                      bytes_to_float(reply.slice(pos + 26, pos + 30))
                    );
                    this.log100msDataV2.push(
                      bytes_to_float(reply.slice(pos + 30, pos + 34))
                    );
                    this.log100msDataTemp.push(
                      bytes_to_float(reply.slice(pos + 34, pos + 38))
                    );
                  }
                }
                this.log100msDataIsOk = true;
              }
            } else {
              console.error("response error");
              // this.log100msDataTime.push(dt_start);
              // this.log100msDataRP.push(null);
              // this.log100msDataS1.push(null);
              // this.log100msDataS2.push(null);
              // this.log100msDataM12.push(null);
              // this.log100msDataSetpoint.push(null);
              // this.log100msDataV1.push(null);
              // this.log100msDataV2.push(null);
              // this.log100msDataTemp.push(null);
              // for (let i = 0; i < 6; i++) {
              //   const dt_stamp = dt_start + (i * (dt_end - dt_start)) / 5;
              //   this.log100msDataTime.push(dt_stamp);
              //   this.log100msDataRP.push(Math.sin(dt_stamp / 1000000) * 20 + 20);
              //   this.log100msDataS1.push(
              //     Math.sin(dt_stamp / 1000000 - 100000) * 20 + 20
              //   );
              //   this.log100msDataS2.push(
              //     Math.sin(dt_stamp / 1000000 - 200000) * -20 + 20
              //   );
              //   this.log100msDataM12.push(
              //     Math.sin(dt_stamp / 1000000 - 300000) * 20 + 10
              //   );
              //   this.log100msDataSetpoint.push(
              //     Math.sin(dt_stamp / 1000000 - 500000) * 20 + 30
              //   );
              //   this.log100msDataV1.push(1 * Math.sin(dt_stamp / 1000000));
              //   this.log100msDataV2.push(-1 * Math.sin(dt_stamp / 1000000));
              //   this.log100msDataTemp.push(Math.sin(dt_stamp / 1000000) * 10 + 30);
              // }
            }
            this.log100msDataBusy = false;
          });
      } else {
        console.error("port disconnected");
      }
    },
    generateRandomData() {
      const len = this.demoDataX.length;
      if (len >= this.maxTime * 10) {
        this.demoDataX.shift();
        this.demoDataY["RS"].shift();
        this.demoDataY["ISA"].shift();
        this.demoDataY["ISB"].shift();
        this.demoDataY["ISC"].shift();
        this.demoDataY["SP"].shift();
        this.demoDataY["IV"].shift();
        this.demoDataY["EV"].shift();
        this.demoDataY["IEV"].shift();
        this.demoDataY["DT"].shift();
      }

      this.demoDataX = this.demoDataX.concat(new Date().toISOString());
      this.demoDataY["RS"] = this.demoDataY["RS"].concat(
        Math.random() * 10 + 40
      );
      this.demoDataY["ISA"] = this.demoDataY["ISA"].concat(
        Math.random() * 10 + 40
      );
      this.demoDataY["ISB"] = this.demoDataY["ISB"].concat(
        Math.random() * 10 + 40
      );
      this.demoDataY["ISC"] = this.demoDataY["ISC"].concat(
        Math.random() * 10 + 40
      );
      this.demoDataY["SP"] = this.demoDataY["SP"].concat(45);
      const iv = Math.random() > 0.5 ? 1 : 0;
      const ev = 1 - iv;
      this.demoDataY["IV"] = this.demoDataY["IV"].concat(iv);
      this.demoDataY["EV"] = this.demoDataY["EV"].concat(ev);
      this.demoDataY["IEV"] = this.demoDataY["IEV"].concat(iv - ev);
      this.demoDataY["DT"] = this.demoDataY["DT"].concat(
        Math.random() * 3 + 30
      );

      this.demoFlag = !this.demoFlag;
    },
  },
});
