import PromiseWorker from "promise-worker";
import Worker from "worker-loader!./worker";

const worker = new Worker();
const promiseWorker = new PromiseWorker(worker);

const postMsgopenPort = (_portIndex: number, _baud: number) =>
  promiseWorker.postMessage({
    type: "openPort",
    portIndex: _portIndex,
    baudRate: _baud,
  });

const postMsgClosePort = () =>
  promiseWorker.postMessage({
    type: "closePort",
  });
const postMsgGetPortState = () =>
  promiseWorker.postMessage({
    type: "getPortState",
  });

const postMsgModbusRead = (
  deviceAddr: number,
  modbusAddr: number,
  counts: number
) =>
  promiseWorker.postMessage({
    type: "modbusRead",
    deviceAddr,
    modbusAddr,
    counts /*count of register(16bits)*/,
  });

const postMsgModbusWrite = (
  deviceAddr: number,
  modbusAddr: number,
  counts: number,
  values: Uint8Array
) =>
  promiseWorker.postMessage({
    type: "modbusWrite",
    deviceAddr,
    modbusAddr,
    counts /*count of register(16bits)*/,
    values,
  });

const postMsgModbusPollingData = (deviceAddr: number) =>
  promiseWorker.postMessage({
    type: "modbusPollingData",
    deviceAddr,
  });

const postMsgModbusLogData = (
  deviceAddr: number,
  dt_start: number,
  dt_end: number,
  interval: "minute" | "second" | "ms"
) =>
  promiseWorker.postMessage({
    type: "modbusLogData",
    deviceAddr,
    dt_start,
    dt_end,
    interval,
  });

export default {
  postMsgGetPortState,
  postMsgopenPort,
  postMsgClosePort,
  postMsgModbusRead,
  postMsgModbusWrite,
  postMsgModbusPollingData,
  postMsgModbusLogData,
};
