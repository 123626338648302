export async function read_csv_file<T = Record<string, string>>(
  address: string
): Promise<null | T[]> {
  const response = await fetch(address);
  if (!response.ok) {
    throw new Error("Response not OK");
  }
  const responseText = await response.text();
  const csv = responseText.split(/\r?\n/).map((l) => l.split(";"));
  const headers = csv.shift();
  if (headers == null) {
    return null;
  }
  return csv.map((ln) =>
    Object.fromEntries(ln.map((v, i) => [headers[i], v]))
  ) as unknown as T[];
}
