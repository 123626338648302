import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import CalculateView from "../views/CalculateView.vue";
import ChartsView from "../views/ChartsView.vue";
import EmbeddedSettingsView from "../views/EmbeddedSettingsView.vue";
import HistoryDataView from "../views/HistoryDataView.vue"

const routes: Array<RouteRecordRaw> = [
  { name: "Home", path: "/", component: HomeView },
  { name: "Charts", path: "/charts", component: ChartsView },
  {
    name: "ModuleSettings",
    path: "/module-settings/:group*",
    component: EmbeddedSettingsView,
  },
  { name: "HistoryData", path: "/history-data", component: HistoryDataView },
  {
    name: "UnitCalculator",
    path: "/unit-calculator",
    component: CalculateView,
  },
  { name: "About", path: "/about", component: AboutView },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
